import {initializeApp} from "firebase/app";
import {initializeFirestore} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCx2eeN9QqDfHMEZZl37wDqpwLEVGBAcho",
  authDomain: "oplit-staging.firebaseapp.com",
  //databaseURL: "https://oplit-staging.firebaseio.com",
  projectId: "oplit-staging",
  storageBucket: "oplit-staging.appspot.com",
  messagingSenderId: "921172463152",
  appId: "1:921172463152:web:a8fd7da1ecf2636924e7f5",
  measurementId: "G-9N7K8SY9M0"
};

const BACKEND_URL = "https://api-front-uwdct636tq-ew.a.run.app";
const BACKEND_IMPORT_URL = "https://api-import-uwdct636tq-ew.a.run.app/";
const BACKEND_SSE_URL = "https://api-import-uwdct636tq-ew.a.run.app/";
const BACKEND_DATA_URL = "https://data-api-uwdct636tq-ew.a.run.app/";
const ENABLE_MICROSOFT_SSO = "true" === "true";

const firebaseApp = initializeApp(firebaseConfig);
const firestoreDB = initializeFirestore(firebaseApp, {
  experimentalAutoDetectLongPolling: true,
});

const environment = "staging" as string;

const segment_key = "N3q7G9q4MmLIOV22NMBbTUaLr2zWh6xG";

export {
  firebaseConfig,
  BACKEND_URL,
  BACKEND_IMPORT_URL,
  BACKEND_SSE_URL,
  BACKEND_DATA_URL,
  ENABLE_MICROSOFT_SSO,
  environment,
  segment_key,
  firestoreDB
};

export default firebaseApp;
