import {
  parseDate,
  DATE_DEFAULT_FORMAT,
  type ImportIdObj,
} from "@oplit/shared-module";

function getImportTypesFromImports(imports: ImportIdObj[]) {
  if (!imports?.length) return [];

  let tempImportTypes = imports.reduce((acc: string[], curr: ImportIdObj) => {
    const types: string[] = curr.data;
    const newTypes = types?.filter((type: string) => !acc.includes(type));
    if (!newTypes?.length) return acc;
    if (parseDate(curr.date, false, false, DATE_DEFAULT_FORMAT) < "2021-01-01")
      return acc;
    return [...acc, ...newTypes];
  }, []);
  const includesOfs = tempImportTypes.includes("ofs");
  const includesProduction = tempImportTypes.includes("production");
  tempImportTypes = tempImportTypes.reduce((acc: string[], curr: string) => {
    if (
      [
        "capa",
        "load_spread",
        "production",
        "production_pf",
        "production_st",
        "commandes",
        "cmj",
        "en_cours",
        "gammes",
        "nomenclatures",
      ].includes(curr)
    )
      return acc;
    if (includesOfs && curr === "charge") return acc;
    if (includesProduction && curr === "realise") return acc;
    return [...acc, curr];
  }, []);
  return tempImportTypes;
}

export {getImportTypesFromImports};
